import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */ import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import Box from "./box"
import Icon from "./icon"
import Stack from "./stack"
import Text from "./text"
import Heading from "./heading"

const TeaserBox = (props) => {
  const { title, headingElement, description, to, cta, ...restProps } = props

  return (
    <Box position="relative" {...restProps} sx={{ height: "100%" }}>
      <Link
        to={to}
        sx={{
          display: "block",
          py: [6, 8, 10],
          pl: [6, 8, 10],
          pr: 12,
          borderWidth: 4,
          borderColor: "border",
          height: "100%",
          ":hover, :focus": {
            borderColor: "primary",
          },
          ":hover .teaser-box-square, :focus .teaser-box-square": {
            color: "black",
            bg: "primary",
          },
        }}
      >
        <Stack space={(3, 6)}>
          <Heading as={headingElement} level={5}>
            {title}
          </Heading>
          {description && <Text size={[2, 2, 3]}>{description}</Text>}
        </Stack>
        <Box
          position="absolute"
          bottom={0}
          right={0}
          zIndex="10"
          className="teaser-box-square"
          bg="primary"
          color="black"
          p={3}
          textAlign="center"
          display="inline-flex"
          alignX="center"
          alignY="center"
        >
          <Icon icon="arrow-right" />
        </Box>
      </Link>
    </Box>
  )
}

TeaserBox.propTypes = {
  cta: PropTypes.string.isRequired,
  headingElement: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

TeaserBox.defaultProps = {
  headingElement: "h4",
}

export default TeaserBox
