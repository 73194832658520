import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "./box"
import Button from "./button"
import Grid from "./grid"
import Heading from "./heading"
import Image from "./image"
import Small from "./small"
import Stack from "./stack"
import Text from "./text"

const HoelderlinLeuchtetBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      hoelderlinLeuchtet: file(
        relativePath: { eq: "leichte-sprache/hoelderlin-leuchtet-square.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 500, layout: CONSTRAINED)
        }
      }
      hoelderlinLeuchtetSmall: file(
        relativePath: { eq: "leichte-sprache/hoelderlin-leuchtet.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 640, height: 300, layout: CONSTRAINED)
        }
      }
    }
  `)

  const subject = `Klassensatz „Hölderlin leuchtet“ zur Ausleihe`
  const message = `Liebes Team des Hölderlinturms,%0D%0A%0D%0A wir möchten einen Klassensatz ›Hölderlin leuchtet‹ für den Unterricht ausleihen:%0D%0A%0D%0A Schule und Adresse:%0D%0A Klassenstufe:%0D%0A Wieviele Exemplare werden benötigt?%0D%0A Gewünschter Zeitraum für die Ausleihe?%0D%0A`

  return (
    <Box bg="muted">
      <Grid
        columns={[1, 1, 2]}
        space={0}
        reverse={[false, false, true]}
        alignY="center"
      >
        <Box>
          <Box display={["block", "block", "none"]}>
            <Image
              image={
                data.hoelderlinLeuchtetSmall.childImageSharp.gatsbyImageData
              }
              alt="Ein Stapel Bücher. Das Titelbild des obersten Buches zeigt eine Illustration von Hölderlin am Schreibtisch."
            />
          </Box>
          <Box display={["none", "none", "block"]}>
            <Image
              image={data.hoelderlinLeuchtet.childImageSharp.gatsbyImageData}
              alt="Ein Stapel Bücher. Das Titelbild des obersten Buches zeigt eine Illustration von Hölderlin am Schreibtisch."
            />
          </Box>
        </Box>

        <Box p={[6, 8, 12]}>
          <Stack space={6}>
            <Heading as="h2" level={3}>
              ›Hölderlin leuchtet‹ für den Unterricht
            </Heading>
            <Text size={[2, 2, 3]}>
              Schulklassen, die sich Friedrich Hölderlin und seinen Gedichten in
              Einfacher Sprache annähern wollen, bieten wir einen Klassensatz
              unseres Lese-Buchs ›Hölderlin leuchtet‹ mit Texten in Einfacher
              Sprache von Stephanie Jaeckel und Illustrationen von Jette von
              Bodecker zur Ausleihe an.
            </Text>
            <Button
              href={`mailto:hoelderlinturm@tuebingen.de?subject=${subject}&body=${message}`}
            >
              Jetzt anfragen
            </Button>
            <Small>
              Die Bücher können gegen ein Pfand an der Museumskasse entliehen
              werden. Gegen eine Gebühr von 20 Euro senden wir die Bücher auch
              per Post.
            </Small>
          </Stack>
        </Box>
      </Grid>
    </Box>
  )
}

export default HoelderlinLeuchtetBanner
